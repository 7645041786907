<template>
  <div class="service-setting">
    <PageTitle
      title="服務地點設定"
      cyBtn1="new-service-btn"
      btn="新增"
      @btnClick="$router.push({
        name: 'CreateReservationService'
      })"
    />

    <FiltersContainer>
      <el-input v-model="nameSearch" clearable placeholder="輸入服務地點名稱" @keypress.enter.native="refresh" @clear="refresh">
        <i slot="suffix" class="el-input__icon el-icon-search" @click="refresh" />
      </el-input>
    </FiltersContainer>

    <section>
      <el-table v-loading="loading" :data="serviceList" empty-text="暫無數據">
        <EmptyBlock slot="empty" />
        <el-table-column prop="name" label="圖片" align="center" width="150">
          <template slot-scope="scope">
            <img style="margin: auto;" width="90" :src="imgSource(scope.row.Image)" alt="">
          </template>
        </el-table-column>
        <el-table-column prop="name" label="名稱" width="150" align="center" />
        <el-table-column prop="isPublic" label="公開顯示" width="120" align="center">
          <template slot-scope="scope">
            <Tag :type="tagType(scope.row.isPublic)">{{ (scope.row.isPublic)? '是' : '否' }}</Tag>
          </template>
        </el-table-column>
<!--        <el-table-column prop="price" label="實際價格" width="120" align="center" />-->
        <el-table-column prop="showPrice" label="顯示價格" width="120" align="center">
          <template slot-scope="scope">
            {{ (! scope.row.showPrice || scope.row.showPrice === '0')? '-' : scope.row.showPrice }}
          </template>
        </el-table-column>
        <el-table-column prop="bookingTime" label="實際預約時間" width="150" align="center" />
        <el-table-column prop="showTime" label="顯示預約時間" width="150" align="center">
          <template slot-scope="scope">
            {{ (! scope.row.showTime || scope.row.showTime === 0)? '-' : scope.row.showTime }}
          </template>
        </el-table-column>
        <el-table-column prop="address" label="服務地點" width="150" align="center" >
          <template slot-scope="scope">
            {{ (!scope.row.address) ? '-' : scope.row.address }}
          </template>
        </el-table-column>
        <el-table-column class="test" prop="description" label="服務敘述" align="center" show-overflow-tooltip />
        <el-table-column prop="order" label="排序" align="center" />
        <el-table-column prop="name" label="操作" fixed="right" width="110" align="center">
          <template slot-scope="scope">
            <TableEditBtnGroup
              @edit="$router.push({
                name: 'CreateReservationService',
                params: {
                  serviceId: scope.row.id
                }
              })"
              @delete="deleteDialog = true, selectRow=scope.row"
            />
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="serviceCount"
        @pageChange="refresh"
      />
    </section>

    <image-cropper v-if="uploadDialog" :image="formData.img" @uploaded="getImage" @close="uploadDialog = false" />

    <el-dialog :title="dialogTitle" :visible.sync="showDialog" :close-on-click-modal="false" @close="resetForm()">
      <el-form ref="form" :model="formData" label-position="top" :rules="formRules">
        <el-form-item label="圖片" prop="image">
          <UploadButton
            cyUploadBtn="service-img-upload-btn"
            cyUploadedImg="service-img-uploaded"
            :img="formData.avatar"
            :isAvatar="true"
            @change="loadImg"
          />
        </el-form-item>
        <el-form-item label="公開顯示">
          <el-switch
            v-model="formData.isPublic"
            active-text="是"
            inactive-text="否"
          />
        </el-form-item>
        <el-form-item label="名稱" prop="name">
          <el-input v-model="formData.name" :maxlength="55" show-word-limit data-cy="name-input" placeholder="名稱" />
        </el-form-item>
        <el-form-item label="服務敘述" prop="description">
          <el-input
            v-model="formData.description"
            data-cy="description-input"
            type="textarea"
            autosize
            maxlength="500"
            show-word-limit
            placeholder="請輸入服務敘述"
          />
        </el-form-item>
        <el-form-item label="實際預約時間(分鐘)" prop="bookingTime">
          <div class="flex flex-col">
            <el-input
              v-model="formData.bookingTime"
              data-cy="service-time-input"
              placeholder="請輸入實際預約時間"
            />
            <el-checkbox
              v-model="formData.hideTime"
              data-cy="hide-service-time"
            >
              隱藏實際預約時間
            </el-checkbox>
          </div>
        </el-form-item>
        <el-form-item
          v-if="formData.hideTime"
          data-cy="show-service-time-input"
          label="顯示預約時間"
          prop="showTime"
        >
          <el-input v-model="formData.showTime" placeholder="自定義 只允許數字  (0 視為不隱藏，將顯示實際預約時間)" />
        </el-form-item>
        <el-form-item label="實際價格" prop="price">
          <div class="flex flex-col">
            <el-input v-model="formData.price" data-cy="service-price-input" placeholder="請輸入實際價格" />
            <el-checkbox v-model="formData.hidePrice" data-cy="hide-service-price">隱藏實際價格</el-checkbox>
          </div>
        </el-form-item>
        <el-form-item v-if="formData.hidePrice" label="顯示價格" prop="showPrice">
          <el-input v-model="formData.showPrice" :maxlength="40" show-word-limit data-cy="show-service-price-input" placeholder="自定義 範例: 500元起跳 (0 視為不隱藏，將顯示實際預約時間)" />
        </el-form-item>
        <el-form-item label="排序" prop="order">
          <el-input v-model="formData.order" data-cy="order-input" placeholder="請輸入排序" />
        </el-form-item>
        <el-form-item label="綁定服務類別" prop="category">
          <ServiceCategorySelect data-cy="category-binding-select" :model.sync="formData.serviceCategorys" multiple :showAll="true" />
        </el-form-item>
        <el-form-item label="綁定車輛車次" prop="unit">
          <ServiceUnitSelect data-cy="unit-binding-select" :model.sync="formData.serviceUnits" multiple :showAll="true" />
        </el-form-item>
        <el-form-item label="附加服務">
          <AttachServiceSelect data-cy="attach-service-binding-select" :model.sync="formData.attachService" multiple :showAll="true" />
        </el-form-item>
      </el-form>

      <div slot="footer">
        <el-button data-cy="dialog-cancel-btn" plain @click="showDialog=false, resetForm()">返回</el-button>
        <el-button data-cy="dialog-confirm-btn" type="primary" @click="dialogConfirm">{{ (dialogType === 'create')? '新增' : '儲存' }}</el-button>
      </div>
    </el-dialog>

    <DeleteDialog
      v-if="deleteDialog"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="deleteDialog = false"
      @delete="deleteService(), deleteDialog = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ServiceCategorySelect from '@/components/Select/ServiceCategorySelect.vue'
import AttachServiceSelect from '@/components/Select/AttachServiceSelect.vue'
import ServiceUnitSelect from '@/components/Select/ServicesUnitSelect.vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { CreateService, GetService, GetServiceCount, DeleteService, UpdateService, FindService } from '@/api/service'
import DeleteDialog from '@/components/Dialog/DeleteDialog'
import UploadButton from '@/components/Button/UploadButton.vue'
import ImageCropper from '@/components/ImageCropper.vue'

// Utils
import { noEmptyRules, isDigitRules, rangeRules } from '@/validation'
import { pageStartIndex } from '@/utils/table'
import formUtils from '@/utils/form'
import { dialogTitle } from '@/utils/dialog'
import { extractList, imgSrc } from '@/utils/helper'

// // import * as cssvars from '@/styles/ohbot/_variables.scss'

export default {
  name: 'ServiceSetting',
  components: {
    UploadButton,
    ImageCropper,
    DeleteDialog,
    ServiceCategorySelect,
    ServiceUnitSelect,
    AttachServiceSelect,
    EmptyBlock,
  },

  data: () => ({
    nameSearch: '',
    loading: false,
    showDialog: false,
    deleteDialog: false,
    uploadDialog: false,
    dialogType: '',

    selectRow: null,
    selectService: null,

    serviceList: [],
    serviceCount: 0,

    avatarChanged: false,
    // imgSize: 90,
    imgSize: 90,

    formData: {
      img: null,
      avatar: null,
      name: '',
      description: '',
      hidePrice: false,
      hideTime: false,
      price: '',
      showPrice: '',
      showTime: '',
      bookingTime: '',
      order: 100,
      isPublic: true,
      attachService: [],
      serviceCategorys: [],
      serviceUnits: [],
    },

    formRules: {
      name: noEmptyRules(),
      description: noEmptyRules(),
      price: [isDigitRules(), noEmptyRules(), rangeRules()],
      // showPrice: [isDigitRules(false)],
      showTime: [isDigitRules(false), rangeRules()],
      bookingTime: [noEmptyRules(), isDigitRules(), rangeRules()],
      order: [noEmptyRules(), isDigitRules(), rangeRules()],
    },

    tableOptions: {
      page: 1,
      pageLimit: 10,
    },
  }),
  computed: {
    ...mapGetters([
      'shop',
    ]),

    dialogTitle () {
      return dialogTitle(
        this.dialogType,
        {
          create: '新增服務項目',
          update: '更新服務項目',
        },
      )
    },

    pageStartIndex () {
      return pageStartIndex(this.tableOptions.page, this.tableOptions.pageLimit)
    },

    categoryList () {
      const list = []
      this.formData.serviceCategorys.forEach(item => {
        list.push(item.id)
      })
      return list
    },

    serviceUnitList () {
      const list = []
      this.formData.serviceUnits.forEach(item => {
        list.push(item.id)
      })
      return list
    },
  },
  async mounted () {
    await this.refresh()
  },

  methods: {
    tagType (val) {
      let type = 'info'
      if (val) type = 'action'
      //  if(!val) type = 'danger'
      return type
    },
    imgSource (image) {
      return imgSrc(this.imgSize, image)
    },

    getImage (data) {
      this.formData.avatar = data
      this.avatarChanged = true
      this.uploadDialog = false
    },
    loadImg (img) {
      this.formData.img = img
      this.uploadDialog = true
    },
    async refresh () {
      this.loading = true
      await this.getService()
      await this.getServiceCount()
      this.loading = false
    },

    async dialogConfirm () {
      if (!await this.checkForm()) return
      const type = this.dialogType
      if (type === 'create') this.createService()
      if (type === 'update') this.updateService()
      this.showDialog = false
    },

    syncFormData (row) {
      this.formData.name = row.name
      this.formData.avatar = row.Image
      this.formData.description = row.description
      this.formData.price = row.price

      if (row.showPrice && row.showPrice !== '0') {
        this.formData.hidePrice = true
        this.formData.showPrice = row.showPrice
      }
      if (row.showTime && row.showTime !== '0') {
        this.formData.hideTime = true
        this.formData.showTime = row.showTime
      }
      this.formData.bookingTime = row.bookingTime
      this.formData.order = row.order
      this.formData.isPublic = row.isPublic
    },

    //= > 檢查表單輸入驗證
    async checkForm () {
      return await formUtils.checkForm(this.$refs.form)
    },

    //= > 重置表單
    resetForm () {
      formUtils.resetForm(this.$refs.form)
      this.formData = {
        img: null,
        avatar: null,
        name: '',
        description: '',
        hidePrice: false,
        hideTime: false,
        price: '',
        showPrice: '',
        showTime: '',
        bookingTime: '',
        order: 100,
        isPublic: true,
        attachService: [],
        serviceCategorys: [],
        serviceUnits: [],
      }
    },

    //= > 創建服務
    async createService () {
      const form = this.formData
      if (!form.hidePrice) form.showPrice = '0'
      if (!form.hideTime) form.showTime = '0'
      try {
        await CreateService({
          shopId: this.shop,
          name: form.name,
          image: (!form.avatar) ? undefined : this.formData.avatar.id,
          description: form.description,
          price: form.price,
          showPrice: (form.showPrice === '') ? '0' : form.showPrice,
          showTime: (form.showTime === '') ? 0 : form.showTime,
          bookingTime: form.bookingTime,
          order: form.order,
          isPublic: form.isPublic,
          appointmentCategories: this.categoryList,
          appointmentServiceAttaches: extractList('id', this.formData.attachService),
          appointmentUnits: this.serviceUnitList,
        })
        await this.refresh()
        this.$message.success('新增服務成功!')
      } catch (error) {
        this.$message.error({
          message: error || error.message,
        })
        console.log('錯誤', error)
      }
    },

    //= > 刪除服務
    async deleteService () {
      await DeleteService({
        shopId: this.selectRow.ShopId,
        id: this.selectRow.id,
      })

      await this.refresh()
      this.$message.success('已刪除服務!')
    },

    //= > 更新服務
    async updateService () {
      try {
        const form = this.formData
        if (!form.hidePrice) form.showPrice = '0'
        if (!form.hideTime) form.showTime = '0'
        await UpdateService({
          shopId: this.shop,
          id: this.selectService.id,
          name: form.name,
          image: (this.avatarChanged) ? this.formData.avatar.id : undefined,
          description: form.description,
          price: form.price,
          showPrice: (form.showPrice === '') ? '0' : form.showPrice,
          showTime: (form.showTime === '') ? 0 : form.showTime,
          bookingTime: form.bookingTime,
          order: form.order,
          isPublic: form.isPublic,
          appointmentCategories: this.categoryList,
          appointmentServiceAttaches: extractList('id', this.formData.attachService),
          appointmentUnits: this.serviceUnitList,
        })

        await this.refresh()
        this.$message.success('更新服務成功!')
      } catch (error) {
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    //= > 取得服務
    async getService () {
      try {
        const startIndex = this.pageStartIndex
        const limit = this.tableOptions.pageLimit
        const res = await GetService({
          shopId: this.shop,
          start: startIndex,
          limit,
          name: (this.nameSearch === '') ? undefined : this.nameSearch,
        })
        this.serviceList = res
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },
    //= > 查找特定服務
    async findService () {
      try {
        const res = await FindService({
          shopId: this.shop,
          id: this.selectRow.id,
        })
        this.selectService = res

        this.formData.attachService = this.selectService.AppointmentServiceAttaches.filter(item => !item.isRemove)
        this.formData.serviceUnits = this.selectService.AppointmentUnits.filter(item => !item.isRemove)
        this.formData.serviceCategorys = this.selectService.AppointmentCategories.filter(item => !item.isRemove)
      } catch (error) {
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    //= > 取得服務總數
    async getServiceCount () {
      try {
        const res = await GetServiceCount({
          shopId: this.shop,
          name: (this.nameSearch === '') ? undefined : this.nameSearch,
        })
        this.serviceCount = res.data.count
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    openDialog (type) {
      this.dialogType = type
      this.showDialog = true
    },
  },
}
</script>

<style scoped lang="scss">

.test {
  overflow: auto;
}
</style>
